<template>
  <button
    type="button"
    :class="[className, { active: isActive }]"
    :title="this.title"
    :data-title="this.title"
    v-on:click="toogleState"
  >
    <icon-base
      width="17"
      height="19"
      viewBox="0 0 17 19"
      :icon-name="this.title"
      v-if="this.state == '3d'"
    >
      <IconThreeDMode />
    </icon-base>
     <icon-base
      width="15"
      height="15"
      viewBox="0 0 15 15"
      :icon-name="this.title"
      v-if="this.state == '2d'"
    >
     <IconTwoDMode  />
    </icon-base>
    
  </button>
</template>

<script>
import IconBase from "../icons/IconBase";
import IconThreeDMode from "../icons/IconThreeDMode";
import IconTwoDMode from "../icons/IconTwoDMode";

export default {
  name: "BtnClose",
  props: {
    className: { default: "btn btn-icon tooltip fade" },
  },
  components: {
    IconBase,
    IconThreeDMode,
    IconTwoDMode,
  },
  data() {
    return {
      title: "3D View",
      state: "3d",
      isActive: false,
    };
  },
  methods: {
    toogleState() {
      if (this.state == "3d") {
        this.state = "2d";
        this.title = "2D View";
      } else {
        this.state = "3d";
        this.title = "3D View";
      }
      this.isActive = !this.isActive;
    },
  },
};
</script>