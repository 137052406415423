<template>
  <button
    type="button"
    :class="[className, { active: isActive }]"
    :title="this.title"
    :data-title="this.title"
    v-on:click="toogleState"
  >
    <icon-base
      width="19"
      height="19"
      viewBox="0 0 19 19"
      :icon-name="this.title"
    >
      <icon-first-person v-if="isActive" />
      <icon-third-person v-else />
    </icon-base>
  </button>
</template>

<script>
import IconBase from "../icons/IconBase";
import IconFirstPerson from "../icons/IconFirstPerson";
import IconThirdPerson from "../icons/IconThirdPerson";
export default {
  name: "BtnPerspectiveFPP",
  props: {
    isState: { type: String },
    className: { default: "btn btn-icon tooltip fade" },
  },
  components: {
    IconBase,
    IconFirstPerson,
    IconThirdPerson,
  },
  data() {
    return {
      title: "Third Person",
      state: "orbit",
      isActive: false,
    };
  },
  methods: {
    toogleState() {
      if (this.state == "orbit") {
        this.state = "firstPerson";
        this.title = "First Person";
      } else {
        this.state = "orbit";
        this.title = "Third Person";
      }
      this.isActive = !this.isActive;
      // this.$emit("state", this.state);
    },
  },
  created() {
    if (this.isState == "orbit") {
      this.state = "orbit";
      this.title = "Third Person";
      this.isActive = false;
    } else {
      this.state = "firstPerson";
      this.title = "First Person";
      this.isActive = true;
    }
  },
};
</script>