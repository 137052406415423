<template>
  <path
    d="M4.375 8.75H0.875C0.642936 8.75 0.420376 8.84219 0.256282 9.00628C0.0921872 9.17038 0 9.39294 0 9.625C0 9.85706 0.0921872 10.0796 0.256282 10.2437C0.420376 10.4078 0.642936 10.5 0.875 10.5H3.5V13.125C3.5 13.3571 3.59219 13.5796 3.75628 13.7437C3.92038 13.9078 4.14294 14 4.375 14C4.60706 14 4.82962 13.9078 4.99372 13.7437C5.15781 13.5796 5.25 13.3571 5.25 13.125V9.625C5.25007 9.51008 5.22748 9.39626 5.18353 9.29007C5.13958 9.18389 5.07513 9.0874 4.99386 9.00614C4.9126 8.92487 4.81611 8.86042 4.70993 8.81647C4.60374 8.77252 4.48992 8.74993 4.375 8.75Z"
  />
  <path
    d="M13.125 8.75H9.625C9.51008 8.74993 9.39626 8.77252 9.29007 8.81647C9.18389 8.86042 9.0874 8.92487 9.00614 9.00614C8.92487 9.0874 8.86042 9.18389 8.81647 9.29007C8.77252 9.39626 8.74993 9.51008 8.75 9.625V13.125C8.75 13.3571 8.84219 13.5796 9.00628 13.7437C9.17038 13.9078 9.39294 14 9.625 14C9.85706 14 10.0796 13.9078 10.2437 13.7437C10.4078 13.5796 10.5 13.3571 10.5 13.125V10.5H13.125C13.3571 10.5 13.5796 10.4078 13.7437 10.2437C13.9078 10.0796 14 9.85706 14 9.625C14 9.39294 13.9078 9.17038 13.7437 9.00628C13.5796 8.84219 13.3571 8.75 13.125 8.75Z"
  />
  <path
    d="M9.625 5.25H13.125C13.3571 5.25 13.5796 5.15781 13.7437 4.99372C13.9078 4.82962 14 4.60706 14 4.375C14 4.14294 13.9078 3.92038 13.7437 3.75628C13.5796 3.59219 13.3571 3.5 13.125 3.5H10.5V0.875C10.5 0.642936 10.4078 0.420376 10.2437 0.256282C10.0796 0.0921872 9.85706 0 9.625 0C9.39294 0 9.17038 0.0921872 9.00628 0.256282C8.84219 0.420376 8.75 0.642936 8.75 0.875V4.375C8.74993 4.48992 8.77252 4.60374 8.81647 4.70993C8.86042 4.81611 8.92487 4.9126 9.00614 4.99386C9.0874 5.07513 9.18389 5.13958 9.29007 5.18353C9.39626 5.22748 9.51008 5.25007 9.625 5.25Z"
  />
  <path
    d="M4.375 1.40658e-07C4.26007 -6.50389e-05 4.14626 0.022523 4.04007 0.0664728C3.93389 0.110423 3.8374 0.174872 3.75614 0.256136C3.67487 0.337401 3.61042 0.433886 3.56647 0.540075C3.52252 0.646264 3.49993 0.760075 3.5 0.875V3.5H0.875C0.642936 3.5 0.420376 3.59219 0.256282 3.75628C0.0921872 3.92038 0 4.14294 0 4.375C0 4.60706 0.0921872 4.82962 0.256282 4.99372C0.420376 5.15781 0.642936 5.25 0.875 5.25H4.375C4.48992 5.25007 4.60374 5.22748 4.70993 5.18353C4.81611 5.13958 4.9126 5.07513 4.99386 4.99386C5.07513 4.9126 5.13958 4.81611 5.18353 4.70993C5.22748 4.60374 5.25007 4.48993 5.25 4.375V0.875C5.25007 0.760075 5.22748 0.646264 5.18353 0.540075C5.13958 0.433886 5.07513 0.337401 4.99386 0.256136C4.9126 0.174872 4.81611 0.110423 4.70993 0.0664728C4.60374 0.022523 4.48992 -6.50389e-05 4.375 1.40658e-07V1.40658e-07Z"
  />
</template>