<template>
  <button type="button" :class="[className, { active: isActive }]">
    <slot> </slot>
  </button>
</template>

<script>
export default {
  name: "ButtonText",
  props: {
    className: { default: "btn btn-text" },
    isState: { type: String },
    state: { type: String },
  },
  data() {
    return {
      isActive: false,
    };
  },
  watch: {
    isState: function (val) {
      if (this.state == val) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },
  },
  created() {
    if (this.state == this.isState) {
      this.isActive = true;
    }
  },
};
</script>