<template>
  <button
    type="button"
    :class="[className, { active: isActive }]"
    :title="this.state"
    v-on:click="toggleState"
    :data-title="this.title"
  >
    <icon-base
      width="16"
      height="16"
      viewBox="0 0 16 16"
      icon-name="icon-perspective"
    >
      <icon-perspective v-if="this.state == 'perspective'" />
      <icon-ortho v-if="this.state == 'ortho'" />
    </icon-base>
  </button>
</template>


<script>
import IconBase from "../icons/IconBase";
import IconPerspective from "../icons/IconPerspective";
import IconOrtho from "../icons/IconOrtho";
export default {
  name: "BtnPerspectiveOrtho",
  props: {
    name: { type: String },
    isState: { type: String },
    className: { default: "btn btn-icon tooltip fade" },
  },
  components: {
    IconBase,
    IconPerspective,
    IconOrtho,
  },
  data() {
    return {
      state: "perspective",
      title: "Perspective",
      isActive: true,
    };
  },
  methods: {
    toggleState() {
      if (this.state == "perspective") {
        this.state = "ortho";
        this.title = "Ortho";
        this.isActive = false;
      } else {
        this.state = "perspective";
        this.title = "Perspective";
        this.isActive = true;
      }
      //   this.$emit("state", this.state);
    },
  },
  created() {
    if (this.state == this.isState) {
      if (this.isState == "ortho") {
        this.state = "ortho";
        this.title = "Ortho";
        this.isActive = false;
      } else {
        this.state = "perspective";
        this.title = "Perspective";
        this.isActive = true;
      }
    }
  },
};
</script>

<style lang="scss">
.xeokit-btn {
  color: $primary !important;
}
</style>