<template>
  <button
    type="button"
    :class="[className, { active: isActive }]"
    :title="this.title"
    :data-title="this.title"
    v-on:click="toogleState"
  >
    <icon-base width="11" height="16" viewBox="0 0 11 16" icon-name="Select">
      <icon-select />
    </icon-base>
  </button>
</template>

<script>
import IconBase from "../icons/IconBase";
import IconSelect from "../icons/IconSelect";

export default {
  name: "BtnSelect",
  props: {
    className: { default: "btn btn-icon tooltip fade" },
    state: { type: Boolean },
  },
  components: {
    IconBase,
    IconSelect,
  },
  data() {
    return {
      isActive: this.state,
      title: "Select On",
    };
  },
  watch: {
    state(val) {
      this.isActive = val;
      if (this.isActive) {
        this.title = "Select On";
      } else {
        this.title = "Select Off";
      }
    },
  },
  methods: {
    toogleState() {
      this.isActive = !this.isActive;
      if (this.isActive) {
        this.title = "Select On";
      } else {
        this.title = "Select Off";
      }
    },
  },
};
</script>