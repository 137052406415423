<template>
  <button
    type="button"
    :class="className"
    title="Reset"
    :data-title="this.title"
    v-on:click="emitState"
  >
    <icon-base
      width="16"
      height="16"
      viewBox="0 0 16 16"
      icon-name="icon-reset"
    >
      <icon-reset
    /></icon-base>
  </button>
</template>


<script>
import IconBase from "../icons/IconBase";
import IconReset from "../icons/IconReset";
export default {
  name: "BtnReset",
  props:{className: {
      default: 'btn btn-icon tooltip fade'
  }},
  components: {
    IconBase,
    IconReset,
  },
  data() {
    return {
      state: "ortho",
      title: "Reset",
    };
  },
  methods: {
    emitState() {
      this.$emit("state", this.state);
    },
  },
};
</script>
