<template>
  <button
    type="button"
    :class="[className, { active: isActive }]"
    :title="this.title"
    :data-title="this.title"
    v-on:click="toogleState"
  >
    <icon-base
      width="16"
      height="16"
      viewBox="0 0 16 16"
      icon-name="icon-reset"
    >
      <icon-full-screen v-if="!this.isActive"></icon-full-screen>
      <icon-minimize v-if="this.isActive"></icon-minimize>
    </icon-base>
  </button>
</template>


<script>
import IconBase from "../icons/IconBase";
import IconFullScreen from "../icons/IconFullScreen";
import IconMinimize from "../icons/IconMinimize";
export default {
  name: "BtnFullScreen",
  props: {
    className: {
      default: "btn btn-icon tooltip fade",
    },
     isState: { type: Boolean },
  },
  components: {
    IconBase,
    IconFullScreen,
    IconMinimize,
  },
  data() {
    return {
      title: "Full Screen Off",
      isActive: false,
    };
  },
  watch: {
    isState(val) {
      this.isActive = val;
      if (this.isActive) {
        this.title = "Full Screen On";
      } else {
        this.title = "Full Screen Off";
      }
    },
  },
  methods: {
    toogleState() {
      this.isActive = !this.isActive;
      if (this.isActive) {
        this.title = "Full Screen On";
      } else {
        this.title = "Full Screen Off";
      }
    },
  },
};
</script>
