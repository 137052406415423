<template>
  <g clip-path="url(#clip0)">
    <path
      d="M0.5 8L8 0.5L15.5 8"
      stroke="#525776"
      stroke-width="2"
      fill="none"
      stroke-linejoin="round"
    />
    <path
      d="M13.5 6V15.5H2.5V6"
      stroke="#525776"
      stroke-width="2"
      fill="none"
      stroke-linejoin="round"
    />
    <path
      d="M6 15.5V9.5H10V15.5"
      stroke="#525776"
      stroke-width="2"
      fill="none"
      stroke-linejoin="round"
    />
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="16" height="16" fill="white" />
    </clipPath>
  </defs>
</template>