<template>
  <path
    d="M5 11H1V15H5V11Z"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
    fill="none"
  />
  <path
    d="M15 11H11V15H15V11Z"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
    fill="none"
  />
  <path
    d="M5 1H1V5H5V1Z"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
    fill="none"
  />
  <path
    d="M15 1H11V5H15V1Z"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
    fill="none"
  />
  <path
    d="M3 5L3 11"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
    fill="none"
  />
  <path
    d="M10 13H5"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
    fill="none"
  />
  <path
    d="M10 3L5 3"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
    fill="none"
  />
  <path
    d="M13 5L13 11"
    stroke-width="2"
    stroke-miterlimit="10"
    stroke-linecap="round"
    stroke-linejoin="round"
    fill="none"
  />
</template>