<template>
  <button
    type="button"
    :class="[className, { active: isActive }]"
    :title="this.title"
    :data-title="this.title"
    v-on:click="toogleState"
  >
    <icon-base
      width="19"
      height="16"
      viewBox="0 0 19 16"
      :icon-name="this.title"
    >
      <icon-slice />
    </icon-base>
  </button>
</template>

<script>
import IconBase from "../icons/IconBase";
import IconSlice from "../icons/IconSlice";

export default {
  name: "BtnSlice",
  props: {
    className: { default: "btn btn-icon tooltip fade" },
    isState: { type: Boolean },
  },
  components: {
    IconBase,
    IconSlice,
  },
  data() {
    return {
      title: "Section Status Off",
      isActive: false,
    };
  },
  watch: {
    isState(val) {
      this.isActive = val;
      if (this.isActive) {
        this.title = "Section Status On";
      } else {
        this.title = "Section Status Off";
      }
    },
  },
  methods: {
    toogleState() {
      this.isActive = !this.isActive;
      if (this.isActive) {
        this.title = "Section Status On";
      } else {
        this.title = "Section Status Off";
      }
    },
  },
};
</script>