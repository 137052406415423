<template>
  <button
    type="button"
    :class="className"
    :title="this.title"
  >
    <icon-base
      width="8"
      height="8"
      viewBox="0 0 8 8"
      icon-name="Close"
    >
      <icon-select />
    </icon-base>
  </button>
</template>

<script>
import IconBase from "../icons/IconBase";
import IconSelect from "../icons/IconClose";

export default {
  name: "BtnClose",
  props: {
    className: { default: "btn btn-icon" }
  },
  components: {
    IconBase,
    IconSelect,
  }
};
</script>