<template>
  <div style="width: 100%; height: 100%">
    <div id="myViewer" style="width: 100%; height: 100%; position: relative">
      <div class="tree-view-wrapper parent" :class="{ active: treeViewShow }">
        <Vue3DraggableResizable
          :initW="350"
          :initH="400"
          v-model:x="x"
          v-model:y="y"
          v-model:w="w"
          v-model:h="h"
          v-model:active="active"
          :disabledW="true"
          :draggable="true"
          :resizable="true"
          :parent="true"
        >
          <div class="tree-view-header">
            <div>
              <div class="window-bar">
                <h3 class="window-title">Model</h3>
                <btn-close
                  className="btn btn-icon btn-sm btn-close"
                  @click="toggleTreeView"
                ></btn-close>
              </div>
              <div class="tree-view-hierarchy">
                <btn-text
                  :state="'containment'"
                  :isState="treeViewHierarchyState"
                  :className="'btn btn-text btn-sm'"
                  @click="changeTreeViewHierarchy('containment')"
                  >Objects</btn-text
                >
                <btn-text
                  :state="'types'"
                  :className="'btn btn-text btn-sm'"
                  :isState="treeViewHierarchyState"
                  @click="changeTreeViewHierarchy('types')"
                  >Types</btn-text
                >
                <btn-text
                  :state="'storeys'"
                  :isState="treeViewHierarchyState"
                  :className="'btn btn-text btn-sm'"
                  @click="changeTreeViewHierarchy('storeys')"
                  >Storeys</btn-text
                >
              </div>
            </div>
          </div>
          <div
            id="treeViewContainer"
            ref="treeViewContainer"
            class="treeView"
          ></div>
          <div class="tree-view-extend">
            <icon-base>
              <icon-extend />
            </icon-base>
          </div>
        </Vue3DraggableResizable>
      </div>
      <canvas
        id="myCanvas"
        :class="'cursor-' + this.cursor"
        ref="myCanvas"
      ></canvas>
      <div id="myContent">
        <div id="myToolbar">
          <div class="xeokit-toolbar">
            <div class="xeokit-btn-group" role="group">
              <btn-select
                @click="changeSelected()"
                :state="this.Selectedflag"
              />
              <btn-first-person
                :isState="this.perspectiveFPP"
                @click="changePerspectiveFPP()"
              />
              <btn-perspective-ortho
                :isState="this.perspectiveOrtho"
                @click="changePerspectiveOrtho()"
              />
              <btn-slice
                @click="changeSectionstatus()"
                :isState="this.Sectionflag"
              />
              <btn-three-d-two-d-mode @click="changeThreeDMode()" />
              <btn-full-screen
                @click="toggleFullScreen()"
                :isState="this.fullscreen"
              />
              <btn-tree-view
                @click="toggleTreeView()"
                :isState="this.treeViewShow"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="nav__cube" :class="{ active: threeDMode }">
        <canvas id="myNavCubeCanvas" ref="myNavCubeCanvas"></canvas>
        <canvas
          id="mySectionPlanesOverviewCanvas"
          ref="mySectionPlanesOverviewCanvas"
        ></canvas>
        <btn-reset
          @click="resetView"
          className="btn btn-icon btn-reset tooltip fade"
        />
      </div>
      <div id="cameraPivot" class="camera-pivot-marker"></div>
    </div>
  </div>
</template>


<script>
import { Viewer, CameraMemento, XKTLoaderPlugin, NavCubePlugin, TreeViewPlugin, ContextMenu, SectionPlanesPlugin, math } from "@xeokit/xeokit-sdk/dist/xeokit-sdk.es.js";

import Vue3DraggableResizable from "vue3-draggable-resizable";

import BtnReset from "@/components/UI/buttons/BtnReset";
import BtnPerspectiveOrtho from "@/components/UI/buttons/BtnPerspectiveOrtho";
import BtnFirstPerson from "@/components/UI/buttons/BtnFirstPerson";
import BtnSelect from "@/components/UI/buttons/BtnSelect";
import BtnSlice from "@/components/UI/buttons/BtnSlice";
import BtnTreeView from "@/components/UI/buttons/BtnTreeView";
import BtnClose from "@/components/UI/buttons/BtnClose";
import BtnThreeDTwoDMode from "@/components/UI/buttons/BtnThreeDTwoDMode.vue";
import BtnText from "@/components/UI/buttons/BtnText.vue";

import IconBase from "@/components/UI/icons/IconBase.vue";
import IconExtend from "@/components/UI/icons/IconExtend.vue";
import BtnFullScreen from "../UI/buttons/BtnFullScreen.vue";

export default {
  name: "xktViewerComp",
  props: {
    url: String,
    geoFileUrl: String,
    // metaFileUrl: String,
  },
  components: {
    Vue3DraggableResizable,
    BtnReset,
    BtnPerspectiveOrtho,
    BtnFirstPerson,
    BtnSelect,
    BtnSlice,
    BtnTreeView,
    BtnClose,
    BtnThreeDTwoDMode,
    BtnText,
    IconBase,
    IconExtend,
    BtnFullScreen,
  },
  data() {
    return {
      viewer: undefined,
      model: undefined,
      xktLoader: undefined,
      navCube: undefined,
      sectionPlanes: undefined,
      treeView: undefined,
      treeViewHierarchy: "types",
      canvasContextMenu: undefined,
      objectContextMenu: undefined,
      treeVuewContextMenu: undefined,
      filepath: undefined,
      Highlightedbtntxt: "Highlight ON",
      Sectionbtntxt: "Sections Add OFF",
      Highlightedflag: true,
      Selectedflag: true,
      Sectionflag: false,
      perspectiveOrtho: "perspective",
      perspectiveFPP: "orbit",
      x: 50,
      y: 50,
      h: 300,
      w: 100,
      active: false,
      treeViewShow: false,
      threeDMode: true,
      cursor: "select",
      fullscreen: false,
      cameraMemento: undefined,
    };
  },
  methods: {
    init() {
      // console.log(this.geoFileUrl)

      //url remove extension
      // var n = this.url.lastIndexOf(".");
      // this.filepath = this.url.substring(0, n);

      this.viewer = new Viewer({
        canvasId: this.$refs.myCanvas.id,
        transparent: true,
        // pbrEnabled: true,
        // saoEnabled: true,
      });

      this.viewer.camera.eye = [-2.56, 8.38, 8.27];
      this.viewer.camera.look = [13.44, 3.31, -14.83];
      this.viewer.camera.up = [0.1, 0.98, -0.14];

      this.viewer.cameraFlight.fitFOV = 45;

      this.viewer.scene.xrayMaterial.fillAlpha = 0.1;
      this.viewer.scene.xrayMaterial.fillColor = [0, 0, 0];
      this.viewer.scene.xrayMaterial.edgeAlpha = 0.4;
      this.viewer.scene.xrayMaterial.edgeColor = [0, 0, 0];
      const pivotElement = document.getElementById('cameraPivot');
      this.viewer.cameraControl.navMode = "orbit";
      this.viewer.cameraControl.pivotElement = pivotElement;
      // console.log(this.viewer.cameraControl);
      this.viewer.scene.highlightMaterial.fillAlpha = 0.3;
      this.viewer.scene.highlightMaterial.edgeColor = [1, 1, 0];

      this.navCube = new NavCubePlugin(this.viewer, {
        canvasId: this.$refs.myNavCubeCanvas.id,
        visible: true, // Initially visible (default)
        size: 250, // NavCube size in pixels (default is 200)
        alignment: "topRight", // Align NavCube to top-left of Viewer canvas
        topMargin: 170, // 170 pixels margin from top of Viewer canvas
        cameraFly: true, // Fly camera to each selected axis/diagonal
        cameraFitFOV: 45, // How much field-of-view the scene takes once camera has fitted it to view
        cameraFlyDuration: 0.5, // How long (in seconds) camera takes to fly to each new axis/diagonal
        frontColor: "#E8ECF1",
        backColor: "#E8ECF1",
        leftColor: "#E8ECF1",
        rightColor: "#E8ECF1",
        topColor: "#E8ECF1",
        bottomColor: "#E8ECF1",
      });

      this.sectionPlanes = new SectionPlanesPlugin(this.viewer, {
        overviewCanvasId: this.$refs.mySectionPlanesOverviewCanvas.id,
        overviewVisible: true,
      });

      this.treeView = new TreeViewPlugin(this.viewer, {
        containerElement: this.$refs.treeViewContainer,
        hierarchy: this.treeViewHierarchy,
        autoExpandDepth: 1,
      });

      //Clicking Node Titles
      this.treeView.on("nodeTitleClicked", (e) => {
        const scene = this.viewer.scene;
        const objectIds = [];
        e.treeViewPlugin.withNodeTree(e.treeViewNode, (treeViewNode) => {
          if (treeViewNode.objectId) {
            objectIds.push(treeViewNode.objectId);
          }
        });
        scene.setObjectsXRayed(scene.objectIds, true);
        scene.setObjectsVisible(scene.objectIds, true);
        scene.setObjectsXRayed(objectIds, false);
        this.viewer.cameraFlight.flyTo(
          {
            aabb: scene.getAABB(objectIds),
            duration: 0.5,
          },
          () => {
            setTimeout(function () {
              scene.setObjectsVisible(scene.xrayedObjectIds, false);
              scene.setObjectsXRayed(scene.xrayedObjectIds, false);
            }, 500);
          }
        );
      });

      this.canvasContextMenu = new ContextMenu({
        enabled: true,
        context: {
          viewer: this.viewer,
        },
        items: [
          [
            {
              title: "Hide All",
              getEnabled: function (context) {
                return context.viewer.scene.numVisibleObjects > 0;
              },
              doAction: function (context) {
                context.viewer.scene.setObjectsVisible(
                  context.viewer.scene.visibleObjectIds,
                  false
                );
              },
            },
            {
              title: "Show All",
              getEnabled: function (context) {
                const scene = context.viewer.scene;
                return scene.numVisibleObjects < scene.numObjects;
              },
              doAction: function (context) {
                const scene = context.viewer.scene;
                scene.setObjectsVisible(scene.objectIds, true);
                scene.setObjectsXRayed(scene.xrayedObjectIds, false);
                scene.setObjectsSelected(scene.selectedObjectIds, false);
              },
            },
          ],
          [
            {
              title: "View Fit All",
              doAction: function (context) {
                context.viewer.cameraFlight.flyTo({
                  aabb: context.viewer.scene.getAABB(),
                });
              },
            },
          ],
        ],
      });
      this.objectContextMenu = new ContextMenu({
        items: [
          [
            {
              title: "View Fit",
              doAction: function (context) {
                const viewer = context.viewer;
                const scene = viewer.scene;
                const entity = context.entity;
                viewer.cameraFlight.flyTo(
                  {
                    aabb: entity.aabb,
                    duration: 0.5,
                  },
                  () => {
                    setTimeout(function () {
                      scene.setObjectsHighlighted(
                        scene.highlightedObjectIds,
                        false
                      );
                    }, 500);
                  }
                );
              },
            },
            {
              title: "View Fit All",
              doAction: function (context) {
                const scene = context.viewer.scene;
                context.viewer.cameraFlight.flyTo({
                  projection: "perspective",
                  aabb: scene.getAABB(),
                  duration: 0.5,
                });
              },
            },
            {
              title: "Show in Tree",
              doAction: function (context) {
                const objectId = context.entity.id;
                context.treeViewPlugin.showNode(objectId);
              },
            },
          ],
          [
            {
              title: "Hide",
              getEnabled: function (context) {
                return context.entity.visible;
              },
              doAction: function (context) {
                context.entity.visible = false;
              },
            },
            {
              title: "Hide Others",
              doAction: function (context) {
                const viewer = context.viewer;
                const scene = viewer.scene;
                const entity = context.entity;
                const metaObject = viewer.metaScene.metaObjects[entity.id];
                if (!metaObject) {
                  return;
                }
                scene.setObjectsVisible(scene.visibleObjectIds, false);
                scene.setObjectsXRayed(scene.xrayedObjectIds, false);
                scene.setObjectsSelected(scene.selectedObjectIds, false);
                scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
                metaObject.withMetaObjectsInSubtree((metaObject) => {
                  const entity = scene.objects[metaObject.id];
                  if (entity) {
                    entity.visible = true;
                  }
                });
              },
            },
            {
              title: "Hide All",
              getEnabled: function (context) {
                return context.viewer.scene.numVisibleObjects > 0;
              },
              doAction: function (context) {
                context.viewer.scene.setObjectsVisible(
                  context.viewer.scene.visibleObjectIds,
                  false
                );
              },
            },
            {
              title: "Show All",
              getEnabled: function (context) {
                const scene = context.viewer.scene;
                return scene.numVisibleObjects < scene.numObjects;
              },
              doAction: function (context) {
                const scene = context.viewer.scene;
                scene.setObjectsVisible(scene.objectIds, true);
              },
            },
          ],
          [
            {
              title: "X-Ray",
              getEnabled: function (context) {
                return !context.entity.xrayed;
              },
              doAction: function (context) {
                context.entity.xrayed = true;
              },
            },
            {
              title: "Undo X-Ray",
              getEnabled: function (context) {
                return context.entity.xrayed;
              },
              doAction: function (context) {
                context.entity.xrayed = false;
              },
            },
            {
              title: "X-Ray Others",
              doAction: function (context) {
                const viewer = context.viewer;
                const scene = viewer.scene;
                const entity = context.entity;
                const metaObject = viewer.metaScene.metaObjects[entity.id];
                if (!metaObject) {
                  return;
                }
                scene.setObjectsVisible(scene.objectIds, true);
                scene.setObjectsXRayed(scene.objectIds, true);
                scene.setObjectsSelected(scene.selectedObjectIds, false);
                scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
                metaObject.withMetaObjectsInSubtree((metaObject) => {
                  const entity = scene.objects[metaObject.id];
                  if (entity) {
                    entity.xrayed = false;
                  }
                });
              },
            },
            {
              title: "X-Ray ALL",
              doAction: function (context) {
                const viewer = context.viewer;
                const scene = viewer.scene;
                const entity = context.entity;
                const metaObject = viewer.metaScene.metaObjects[entity.id];
                if (!metaObject) {
                  return;
                }
                scene.setObjectsVisible(scene.objectIds, true);
                scene.setObjectsXRayed(scene.objectIds, true);
                scene.setObjectsSelected(scene.selectedObjectIds, false);
                scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
              },
            },
            {
              title: "Reset X-Ray",
              getEnabled: function (context) {
                return context.viewer.scene.numXRayedObjects > 0;
              },
              doAction: function (context) {
                context.viewer.scene.setObjectsXRayed(
                  context.viewer.scene.xrayedObjectIds,
                  false
                );
              },
            },
          ],
          [
            {
              title: "Select",
              getEnabled: function (context) {
                return !context.entity.selected;
              },
              doAction: function (context) {
                context.entity.selected = true;
              },
            },
            {
              title: "Undo select",
              getEnabled: function (context) {
                return context.entity.selected;
              },
              doAction: function (context) {
                context.entity.selected = false;
              },
            },
            {
              title: "Clear Selection",
              getEnabled: function (context) {
                return context.viewer.scene.numSelectedObjects > 0;
              },
              doAction: function (context) {
                context.viewer.scene.setObjectsSelected(
                  context.viewer.scene.selectedObjectIds,
                  false
                );
              },
            },
          ],
        ],
        enabled: true,
      });

      this.treeViewContextMenu = new ContextMenu({
        items: [
          [
            {
              title: "View Fit",
              doAction: function (context) {
                const scene = context.viewer.scene;
                const objectIds = [];
                context.treeViewPlugin.withNodeTree(
                  context.treeViewNode,
                  (treeViewNode) => {
                    if (treeViewNode.objectId) {
                      objectIds.push(treeViewNode.objectId);
                    }
                  }
                );
                scene.setObjectsVisible(objectIds, true);
                scene.setObjectsHighlighted(objectIds, true);
                context.viewer.cameraFlight.flyTo(
                  {
                    projection: "perspective",
                    aabb: scene.getAABB(objectIds),
                    duration: 0.5,
                  },
                  () => {
                    setTimeout(function () {
                      scene.setObjectsHighlighted(
                        scene.highlightedObjectIds,
                        false
                      );
                    }, 500);
                  }
                );
              },
            },
            {
              title: "View Fit All",
              doAction: function (context) {
                const scene = context.viewer.scene;
                context.viewer.cameraFlight.flyTo({
                  projection: "perspective",
                  aabb: scene.getAABB({}),
                  duration: 0.5,
                });
              },
            },
          ],
          [
            {
              title: "Hide",
              getEnabled: function (context) {
                return !context.entity || context.entity.visible;
              },
              doAction: function (context) {
                context.treeViewPlugin.withNodeTree(
                  context.treeViewNode,
                  (treeViewNode) => {
                    if (treeViewNode.objectId) {
                      const entity =
                        context.viewer.scene.objects[treeViewNode.objectId];
                      if (entity) {
                        entity.visible = false;
                      }
                    }
                  }
                );
              },
            },
            {
              title: "Hide Others",
              doAction: function (context) {
                const scene = context.viewer.scene;
                scene.setObjectsVisible(scene.visibleObjectIds, false);
                scene.setObjectsXRayed(scene.xrayedObjectIds, false);
                scene.setObjectsSelected(scene.selectedObjectIds, false);
                scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
                context.treeViewPlugin.withNodeTree(
                  context.treeViewNode,
                  (treeViewNode) => {
                    if (treeViewNode.objectId) {
                      const entity = scene.objects[treeViewNode.objectId];
                      if (entity) {
                        entity.visible = true;
                      }
                    }
                  }
                );
              },
            },
            {
              title: "Hide All",
              getEnabled: function (context) {
                return context.viewer.scene.visibleObjectIds.length > 0;
              },
              doAction: function (context) {
                context.viewer.scene.setObjectsVisible(
                  context.viewer.scene.visibleObjectIds,
                  false
                );
              },
            },
          ],
          [
            {
              title: "Show",
              doAction: function (context) {
                context.treeViewPlugin.withNodeTree(
                  context.treeViewNode,
                  (treeViewNode) => {
                    if (treeViewNode.objectId) {
                      const entity =
                        context.viewer.scene.objects[treeViewNode.objectId];
                      if (entity) {
                        entity.visible = true;
                        entity.xrayed = false;
                        entity.selected = false;
                      }
                    }
                  }
                );
              },
            },
            {
              title: "Show Others",
              doAction: function (context) {
                const scene = context.viewer.scene;
                scene.setObjectsVisible(scene.objectIds, true);
                scene.setObjectsXRayed(scene.xrayedObjectIds, false);
                scene.setObjectsSelected(scene.selectedObjectIds, false);
                context.treeViewPlugin.withNodeTree(
                  context.treeViewNode,
                  (treeViewNode) => {
                    if (treeViewNode.objectId) {
                      const entity = scene.objects[treeViewNode.objectId];
                      if (entity) {
                        entity.visible = false;
                      }
                    }
                  }
                );
              },
            },
            {
              title: "Show All",
              getEnabled: function (context) {
                const scene = context.viewer.scene;
                return scene.numVisibleObjects < scene.numObjects;
              },
              doAction: function (context) {
                const scene = context.viewer.scene;
                scene.setObjectsVisible(scene.objectIds, true);
                scene.setObjectsXRayed(scene.xrayedObjectIds, false);
                scene.setObjectsSelected(scene.selectedObjectIds, false);
              },
            },
          ],
          [
            {
              title: "X-Ray",
              getEnabled: function (context) {
                return !context.entity || !context.entity.xrayed;
              },
              doAction: function (context) {
                context.treeViewPlugin.withNodeTree(
                  context.treeViewNode,
                  (treeViewNode) => {
                    if (treeViewNode.objectId) {
                      const entity =
                        context.viewer.scene.objects[treeViewNode.objectId];
                      if (entity) {
                        entity.xrayed = true;
                        entity.visible = true;
                        entity.selected = false;
                      }
                    }
                  }
                );
              },
            },
            {
              title: "Undo X-Ray",
              getEnabled: function (context) {
                return !context.entity || context.entity.xrayed;
              },
              doAction: function (context) {
                context.entity.xrayed = false;
              },
            },
            {
              title: "X-Ray Others",
              doAction: function (context) {
                const scene = context.viewer.scene;
                scene.setObjectsVisible(scene.objectIds, true);
                scene.setObjectsXRayed(scene.objectIds, true);
                scene.setObjectsSelected(scene.selectedObjectIds, false);
                scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
                context.treeViewPlugin.withNodeTree(
                  context.treeViewNode,
                  (treeViewNode) => {
                    if (treeViewNode.objectId) {
                      const entity = scene.objects[treeViewNode.objectId];
                      if (entity) {
                        entity.xrayed = false;
                      }
                    }
                  }
                );
              },
            },
            {
              title: "Reset X-Ray",
              getEnabled: function (context) {
                return context.viewer.scene.numXRayedObjects > 0;
              },
              doAction: function (context) {
                context.viewer.scene.setObjectsXRayed(
                  context.viewer.scene.xrayedObjectIds,
                  false
                );
              },
            },
          ],
          [
            {
              title: "Select",
              getEnabled: function (context) {
                return !context.entity || !context.entity.selected;
              },
              doAction: function (context) {
                context.treeViewPlugin.withNodeTree(
                  context.treeViewNode,
                  (treeViewNode) => {
                    if (treeViewNode.objectId) {
                      const entity =
                        context.viewer.scene.objects[treeViewNode.objectId];
                      if (entity) {
                        entity.selected = true;
                        entity.visible = true;
                        entity.xrayed = false;
                      }
                    }
                  }
                );
              },
            },
            {
              title: "Undo Select",
              getEnabled: function (context) {
                return !context.entity || context.entity.selected;
              },
              doAction: function (context) {
                context.treeViewPlugin.withNodeTree(
                  context.treeViewNode,
                  (treeViewNode) => {
                    if (treeViewNode.objectId) {
                      const entity =
                        context.viewer.scene.objects[treeViewNode.objectId];
                      if (entity) {
                        entity.selected = false;
                      }
                    }
                  }
                );
              },
            },
            {
              title: "Clear Selection",
              getEnabled: function (context) {
                return context.viewer.scene.numSelectedObjects > 0;
              },
              doAction: function (context) {
                context.viewer.scene.setObjectsSelected(
                  context.viewer.scene.selectedObjectIds,
                  false
                );
              },
            },
          ],
        ],
        enabled: true,
      });
      this.treeView.on("contextmenu", (e) => {
        this.treeViewContextMenu.context = {
          // Must set context before opening menu
          viewer: e.viewer,
          treeViewPlugin: e.treeViewPlugin,
          treeViewNode: e.treeViewNode,
          entity: e.viewer.scene.objects[e.treeViewNode.objectId],
        };

        this.treeViewContextMenu.show(e.event.pageX, e.event.pageY);
      });

      this.viewer.cameraControl.on("rightClick", (e) => {
        var hit = this.viewer.scene.pick({
          canvasPos: e.canvasPos,
        });

        if (hit && hit.entity.isObject) {
          this.objectContextMenu.context = {
            // Must set context before showing menu
            viewer: this.viewer,
            treeViewPlugin: this.treeView,
            entity: hit.entity,
          };

          this.objectContextMenu.show(e.event.pageX, e.event.pageY);
        } else {
          this.canvasContextMenu.context = {
            // Must set context before showing menu
            viewer: this.viewer,
          };

          this.canvasContextMenu.show(e.event.pageX, e.event.pageY);
        }

        e.event.preventDefault();
      });

      //----------------------------------------------------------------------------------------------------------------------
      // Create an XKT loader plugin, load a model, fit to view
      //----------------------------------------------------------------------------------------------------------------------

      const xktLoader = new XKTLoaderPlugin(this.viewer, {
        objectDefaults: {
          // <<----- Only override color of IfcSpace elements, keep other original IFC colors

          IfcSpace: {
            visible: false,
            pickable: false,
            opacity: 0.2,
          },

          IfcWindow: {
            pickable: false,
            opacity: 0.5,
          },
        },
      });
      const model = xktLoader.load({
        id: "myModel",
        src: this.geoFileUrl,
        // src: "./models/Duplex_creoox.xkt",
        // metaModelSrc: "./models/Doors_Cx_RCSDZzk_1.json",
        edges: true,
      });
      this.model = model;
      model.on("loaded", () => {
        const cameraMemento = new CameraMemento();

        this.cameraMemento = cameraMemento;
        this.viewer.cameraFlight.flyTo(model);
        this.viewer.camera.eye = this.viewer.cameraFlight._eye2;
        this.viewer.camera.look = this.viewer.cameraFlight._look2;
        this.viewer.camera.up = this.viewer.cameraFlight._up2;
        this.cameraMemento.saveCamera(this.viewer.scene);
      });

      var lastEntity = null;

      this.viewer.scene.input.on("mousemove", (coords) => {
        if (this.Highlightedflag) {
          var hit = this.viewer.scene.pick({
            canvasPos: coords,
          });

          if (hit) {
            if (!lastEntity || hit.entity.id !== lastEntity.id) {
              if (lastEntity) {
                lastEntity.highlighted = false;
              }

              lastEntity = hit.entity;
              hit.entity.highlighted = true;
            }
          } else {
            if (lastEntity) {
              lastEntity.highlighted = false;
              lastEntity = null;
            }
          }
        }
      });

      //var i = 1;

      this.viewer.scene.input.on("mouseclicked", (coords) => {
        if (this.Sectionflag) {
          var pickResult = this.viewer.scene.pick({
            canvasPos: coords,
            pickSurface: true, // <<------ This causes picking to find the intersection point on the entity
          });

          if (pickResult) {
            const sectionPlane = this.sectionPlanes.createSectionPlane({
              pos: pickResult.worldPos,
              dir: math.mulVec3Scalar(pickResult.worldNormal, -1),
            });

            this.sectionPlanes.showControl(sectionPlane.id);

            //i++;
          }
        }

        if (this.Selectedflag) {
          var hitSelected = this.viewer.scene.pick({
            canvasPos: coords,
            pickSurface: true,
          });
          if (hitSelected) {
            hitSelected.entity.selected = !hitSelected.entity.selected;
          }
        }
      });
    },
    loadnewxkt() {
      this.init();
    },
    cursorState() {
      if (this.Sectionflag) {
        this.cursor = "sections";
      } else if (this.perspectiveFPP == "firstPerson") {
        this.cursor = "firstPerson";
      } else if (this.Highlightedflag) {
        this.cursor = "select";
      } else if (this.Selectedflag) {
        this.cursor = "select";
      } else if (this.viewer.cameraControl.navMode == "orbit") {
        this.cursor = "orbit";
      } else {
        this.cursor = "default";
      }
    },
    changePerspectiveFPP() {
      if (this.viewer.cameraControl.navMode == "orbit") {
        this.viewer.cameraControl.navMode = "firstPerson";
        this.perspectiveFPP = "firstPerson";
        this.cursorState();
      } else {
        this.viewer.cameraControl.navMode = "orbit";
        this.perspectiveFPP = "orbit";
        this.cursorState();
      }
    },
    changePerspectiveOrtho() {
      if (this.viewer.scene.camera.projection == "perspective") {
        this.viewer.scene.camera.projection = "ortho";
        this.perspectiveOrtho = "ortho";
      } else {
        this.viewer.scene.camera.projection = "perspective";
        this.perspectiveOrtho = "perspective";
      }
    },
    changeHighlighted() {
      this.Highlightedflag = !this.Highlightedflag;
      // console.log(this.Highlightedflag);
      if (!this.Highlightedflag) {
        this.cursorState();
      } else {
        this.cursor = "select";
        this.Sectionflag = false;
        this.sectionPlanes.clear();
      }
    },
    changeSelected() {
      this.Selectedflag = !this.Selectedflag;
      this.Highlightedflag = !this.Highlightedflag;
      if (!this.Selectedflag) {
        this.cursorState();
      } else {
        this.cursor = "select";
        this.Sectionflag = false;
        this.sectionPlanes.clear();
      }
    },
    changeSectionstatus() {
      this.Sectionflag = !this.Sectionflag;
      if (this.Sectionflag) {
        this.Highlightedflag = false;
        this.Selectedflag = false;
        this.cursor = "sections";
      } else {
        this.sectionPlanes.clear();
        this.cursorState();
      }
    },
    clearSections() {
      this.sectionPlanes.clear();
    },
    resetView() {
      this.cameraMemento.restoreCamera(this.viewer.scene, () => {
        return true;
      });
    },
    toggleTreeView() {
      this.treeViewShow = !this.treeViewShow;
    },
    changeThreeDMode() {
      this.threeDMode = !this.threeDMode;
      const tempVec3a = math.vec3();
      const aabb = this.viewer.scene.getAABB(
        this.viewer.scene.visibleObjectIds
      );
      const look2 = math.getAABB3Center(aabb);
      const diag = math.getAABB3Diag(aabb);
      const fitFOV = 45; // fitFOV;
      const sca = Math.abs(diag / Math.tan(fitFOV * math.DEGTORAD));
      const orthoScale2 = diag * 1.3;
      const eye2 = tempVec3a;

      eye2[0] = look2[0] + this.viewer.scene.camera.worldUp[0] * sca;
      eye2[1] = look2[1] + this.viewer.scene.camera.worldUp[1] * sca;
      eye2[2] = look2[2] + this.viewer.scene.camera.worldUp[2] * sca;

      const up2 = math.mulVec3Scalar(
        this.viewer.scene.camera.worldForward,
        -1,
        []
      );
      this.Sectionflag = false;
      this.sectionPlanes.clear();

      if (!this.threeDMode) {
        this.viewer.cameraControl.navMode = "planView";
        this.Sectionflag = false;
        this.sectionPlanes.clear();

        this.viewer.cameraFlight.flyTo({
          eye: eye2,
          look: look2,
          up: up2,
          orthoScale: orthoScale2,
        });
        this.cursorState();
      } else {
        this.viewer.cameraControl.navMode = "orbit";
        this.cameraMemento.restoreCamera(this.viewer.scene, () => {
          return true;
        });
        this.cursorState();
      }
    },
    changeTreeViewHierarchy(val) {
      if (val == "containment") {
        this.treeView.hierarchy = val;
        this.treeViewHierarchy = val;
      } else if (val == "types") {
        this.treeView.hierarchy = val;
        this.treeViewHierarchy = val;
      } else {
        this.treeView.hierarchy = val;
        this.treeViewHierarchy = val;
      }
    },
    toggleFullScreen() {
      const elem = document.getElementById("myViewer");

      if (
        window.fullScreen ||
        (window.innerWidth == screen.width &&
          window.innerHeight == screen.height)
      ) {
        closeFullscreen();
        this.fullScreen = false;
      } else {
        openFullscreen();
        this.fullScreen = true;
      }
      /* View in fullscreen */
      function openFullscreen() {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen();
        }
      }

      /* Close fullscreen */
      function closeFullscreen() {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE11 */
          document.msExitFullscreen();
        }
      }
    },
  },
  computed: {
    treeViewHierarchyState: function () {
      return this.treeViewHierarchy;
    },
  },
  mounted() {
    this.init();
    // let isFullscreen =
    //   document.fullscreenElement ||
    //   document.mozFullScreenElement ||
    //   document.webkitFullscreenElement ||
    //   document.fullScreen ||
    //   document.mozFullScreen ||
    //   document.webkitIsFullScreen;
    // isFullscreen = !!isFullscreen;
    let that = this;
    document.addEventListener("fullscreenchange", () => {
      that.fullscreen = !that.fullscreen;
    });
    document.addEventListener("mozfullscreenchange", () => {
      that.fullscreen = !that.fullscreen;
    });
    document.addEventListener("webkitfullscreenchange", () => {
      that.fullscreen = !that.fullscreen;
    });
    document.addEventListener("msfullscreenchange", () => {
      that.fullscreen = !that.fullscreen;
    });
  },
};
</script>

<style lang="scss">
@import "./xktViewerComp";
</style>