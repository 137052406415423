<template>
    <xktViewerComp :geoFileUrl="geometry" />
    <!-- <xktViewerCompTest /> -->
</template>

<script>
import xktViewerComp from "@/components/xktViewerComp/xktViewerComp.vue";
// import xktViewerCompTest from "@/components/xktViewerComp/xktViewerCompTest.vue";

export default {
  name: "App",
  props:['filename'],
  components: {
    xktViewerComp,
    // xktViewerCompTest
},
  methods: {
    getUrlfromParams() {
      let uri = window.location.href.split("?");
      if (uri.length == 2) {
        let vars = uri[1].split("&");
        let getVars = {};
        let tmp = "";
        vars.forEach(function (v) {
          tmp = v.split("=");
          if (tmp.length == 2) getVars[tmp[0]] = tmp[1];
        });
        this.passurl = getVars["file"];
        this.geometry = getVars["geo"];
      }
    },
  },
  data() {
    return {
      passurl: undefined,
      geometry: undefined,
      store: this.$store.state.name,
    };
  },
  created() {
    this.getUrlfromParams();
  },
};
</script>

<style>
#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%; 
  height: 100%;
}
html,body{
  width: 100%; 
  height: 100%;
  margin: 0px;
}
</style>
