<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke="none"
    d="M0.707111 13.4546C0.316587 13.0641 0.316587 12.4309 0.707112 12.0404L12.0405 0.707076C12.431 0.316552 13.0641 0.316553 13.4547 0.707077V0.707077C13.8452 1.0976 13.8452 1.73077 13.4547 2.12129L2.12132 13.4546C1.7308 13.8451 1.09763 13.8451 0.707111 13.4546V13.4546Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke="none"
    d="M5.70408 13.9194C5.31612 13.5263 5.32028 12.8932 5.71335 12.5052L11.9884 6.31192C12.3815 5.92397 13.0147 5.92812 13.4026 6.3212V6.3212C13.7906 6.71428 13.7864 7.34743 13.3933 7.73538L7.11826 13.9287C6.72518 14.3166 6.09203 14.3125 5.70408 13.9194V13.9194Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke="none"
    d="M11.3015 14.0788C10.9024 13.6971 10.8883 13.0641 11.27 12.6649L12.0886 11.809C12.4703 11.4099 13.1033 11.3958 13.5025 11.7775V11.7775C13.9016 12.1592 13.9157 12.7922 13.534 13.1913L12.7154 14.0473C12.3337 14.4464 11.7006 14.4605 11.3015 14.0788V14.0788Z"
  />
</template>